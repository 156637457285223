<template>
  <router-view />
</template>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  height: 100vh;
  margin: 0;
  // background: pink;
}

nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.download-slider-dialog {
  .el-dialog__header {
    display: none !important;
  }
  .el-dialog__body {
    padding: 0;
    font-size: 0;
  }
}
</style>
