import { createStore } from 'vuex'

export default createStore({
  state: {
    webImgurl: '',
    sliderInfo: {},
  },
  getters: {
  },
  mutations: {
    set_webImgurl(state, url) {
      state.webImgurl = url
      console.log(state.webImgurl)
    },
    SET_SLIDER_INFO: (state, value) => {
      console.log('///////////////////////');
      state.sliderInfo = value
    },
  },
  actions: {
  },
  modules: {
  }
})
